.ordersField {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - var(--panelHeight));
    padding: 5%;
    transition: all 0.5s ease-in;
    overflow-y: auto;
}

.hidden {
    pointer-events: none;
    overflow-y: hidden;
}

.box {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px 15px;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    /* justify-content: space-between; */
    align-items: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.box + .box {
    margin-top: 15px;
}


.description {
    display: flex;
    width: 100%;
    text-align: left;
}

.dutyMarker {

    color: #2468ad;
    margin: 0 auto;
}

.description + .description {
    margin-top: 7px;
}

.popupItm {
    width: 100%;
    flex-direction: column;
    text-align: left;
    display: flex;
}

.clz {
    position: absolute;
    top: 13px;
    right: 13px;
    font-size: 17px;
    color: brown;
    cursor: pointer;
}

.ok {
    font-size: 21px;
    color: green;
    cursor: pointer;
    margin-right: 5px;
}

.okBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid green;
    right: 23px;
    bottom: 120px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
}


.okBtn:active,
.destBtn:active {
    transform: scale(0.97);
}


.cardName {
    font-size: 1.1rem;
}

.cardNameD {
    margin: 0 0 10px;
    font-size: 1.1rem;
    color: #2468ad;
    padding: 5px 10px;
    border: 1px solid #2468ad;
    border-radius: 5px;
    text-align: center;
}

.cardNameD:active {
    transform: scale(0.97);
}

.cardAddr {
    font-size: 1rem;
    margin-top: 9px;
}

.cardCall {
    margin-left: 15px;
}

.cardCallIco {
    color: #2468ad;
    font-size: 41px;
    margin-bottom: -4px;
}

.cardCallIco:active {
    transform: scale(0.97);
}

.cardDate {
    position: absolute;
    top: 10px;
    left: 15px;
}

@media (max-width: 360px) {
    .cardDate {
        font-size: 0.9rem;
    }
}

.cardItems {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

.cardItemsd {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    max-width: 57%;
}

.cardItemsd + .cardItemsd {
    margin-left: auto;
}

.cardClose {
    display: flex;
    width: 100%;
    gap: 2%;
    height: 0;
    align-items: center;
    overflow: hidden;
    transition: 0.2s;
}


.cardProlongation {
    display: flex;
    width: 100%;
    gap: 2%;
    height: 0;
    /*background: #b2b2b2;*/
    overflow-y: hidden;
    transition: 0.2s;
}

.prolog {
    height: 45px;
}

.cardProlongation input,
.cardProlongation select {
    font-size: 1rem;
    text-align: center;
    padding: 5px 7px;
    outline: none;
    border-radius: 5px;
    background: white;
    border: 1px solid grey;
    width: 100%;
    color: #444444;
}

@media (max-width: 360px) {
    .cardProlongation input,
    .cardProlongation select {
        font-size: 0.8rem;
    }
}


.cardProlongation button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 13px;
    border: 1px solid green;
    /* top: 5px; */
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background: none;
}

.okBTN {
    font-size: 15px;
    color: green;
    cursor: pointer;
}

.checkBox {
    margin-left: 10px;
}

.checkBoxInputDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    /*border: 1px solid #2468ad;*/


    border: 1px solid rgb(231, 239, 248);
    background: rgb(241, 248, 254);
    /*box-shadow: 0 2px 15px -5px #2196f3;*/
    /* top: 5px; */
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.timerBtn {
    color: #2468ad;
    font-size: 21px;
    transition: 0.1s;
}


.checkBoxMB,
.checkBoxInput,
.checkBoxInpDestr {
    display: none;
}

.checkBoxInput:checked ~ .checkBoxInputDiv {
    background: #2468ad;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}


.checkBoxInput:checked ~ .checkBoxInputDiv .timerBtn {
    color: #fbfbfb;
}

.ds {
    font-size: 21px;
    /*color: #a70000;*/
    color: #d32f2f;
    cursor: pointer;
    margin-right: 5px;
}

.destBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #d32f2f;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: 0.2s;
}

.checkBoxInpDestr:checked ~ .destBtn {
    color: #fbfbfb;
    /*background: rgba(167, 0, 0, 0.68);*/
    background: #d32f2f;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}

.checkBoxInpDestr:checked ~ .destBtn .ds {
    color: #fbfbfb;
}

.recBTN {
    font-size: 21px;
    color: #ffa000;
    cursor: pointer;
}

.recBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgb(231, 239, 248);
    background: rgb(241, 248, 254);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: 0.2s;
    background: none;
}

.checkBoxMB:checked ~ .recBtn {
    background: #ffa000;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}

.checkBoxMB:checked ~ .recBtn .recBTN {
    color: #fbfbfb;
}

.moneyBack {
    display: flex;
    width: 100%;
    height: 0;
    gap: 3%;
    overflow-y: hidden;
}

.moneyBack input {
    width: calc(97% / 2);
    font-size: 0.8rem;
    text-align: center;
    outline: none;
    border: 1px solid grey;
    border-radius: 5px;
    transition: 0.6s;
}

.mbopn {
    height: 35px;
    margin-bottom: 10px;
}

.recBtn:active,
.checkBoxInputDiv:active,
.checkBoxInputDiv:active {
    transform: scale(0.97);
}