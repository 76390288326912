.newOrderMain {
    display: flex;
    align-items: center;
    width: 100%;
    /*height: calc(100vh - var(--panelHeight));*/
    padding: 1% 5%;
    /*overflow-y: auto;*/
    /*position: relative;*/
}

.newOrder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

     /*min-height: calc(100vh - var(--panelHeight));*/
    padding: 5%;
    transition: all 0.5s ease-in;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    /*transform: translateY(25%);*/
    -webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    color: #444444;
}


.hidden {
    /*opacity: 0.3;*/
    pointer-events: none;
    /*background: rgba(4,4,4,0.6);*/
    /*filter: brightness(0.3);*/
}

.ordersForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.ordersForm input,
.ordersForm select,
.ordersForm option {
    font-size: 1.2rem;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 20px;
    outline: none;
    border-radius: 5px;
    background: var(--white);
    border: 1px solid black;
    /*-webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);*/
    /*-moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);*/
    /*box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);*/
}

.ordersForm input:disabled,
.ordersForm select:disabled,
.ordersForm option:disabled {
    border-color: grey;
    background: var(--mainItemsbackground);
}


.button {
    font-size: 1.1rem;
    padding: 5px 30px;
    /*align-self: flex-end;*/
    border-radius: 5px;
    background: green;
    color: var(--white);
    border: 1px solid green;
    -webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.button:disabled {
    color: grey;
    background: none;
    border-color: grey;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: var(--mainItemsbackground);
}

.itemsField {
    height: 0;
    /*background: rgba(4,4,4,0.1);*/
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.opn {
    /*height: 17vh;*/
    height: 132px;
    overflow: auto;
    margin-bottom: 20px;
    /*padding: 10px 0;*/
}

.box {
    width: 95%;
    padding: 8px 0 8px 30px;
    /*background: var(--white);*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    border-radius: 2px;
    color: var(--white);
    background: var(--mainBlackBackground);
    text-align: left;
    cursor: pointer;
}

.box + .box {
    margin-top: 15px;
}

.refresh {
    font-size: 22px;
    position: absolute;
    right: 7%;
    transform: translate(15px, -49px);
}

.popuItem {
    display: block;
    text-align: left;
}

.popupItm {
    width: 100%;
    min-height: 25px;
}

.pp {
    position: absolute;
    right: 20px;
    bottom: 20px;
    background: rgba(11, 252, 3, 0.5);
    padding: 10px 12px;
    border-radius: 50%;
    cursor: pointer;

}

.ok {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 25px;
    color: rgba(11, 252, 3, 0.5);
    cursor: pointer;
    cursor: pointer;

}

.clz {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    color: brown;
    cursor: pointer;
}

.buttonBlock{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


.checkBox {
    /*--firstColor: #243743;*/
    --firstColor: #b8b7b8;
    /*--secondColor: #28b78d;*/
    --secondColor: rgb(40, 135, 212);
    --width: 35px;
    --height: 22px;
    --offset: 0px;
    --duration: 0.3s;
    cursor: pointer;
    width: var(--width);
}

.checkBox:hover{
    filter: brightness(1.2);
}

.checkBoxInput {
display: none;
}
.checkBoxInput:checked ~ .checkBoxInputDiv {
    background: var(--secondColor);
}

.checkBoxInput:checked ~ .checkBoxInputDiv::before {
    left: calc(var(--width) - var(--height) + var(--offset));
    /*background: var(--secondColor);*/
}


.checkBoxInputDiv {
    /*position: absolute;*/
    width: var(--width);
    height: var(--height);
    border-radius: var(--width);
    background: var(--firstColor);
    position: relative;
    transition: var(--duration);
    box-shadow: inset 0 0 2px rgba(0,0,0, 0.1);
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0, 0.1);
    -moz-box-shadow: inset 0 0 2px rgba(0,0,0, 0.1);
    /*box-shadow: 0px 0px 1px 1px rgba(120, 124, 128, 0.78) inset;*/
}
.checkBoxInputDiv::before{
    content: '';
    position: absolute;
    top: var(--offset);
    left: var(--offset);
    width: calc(var(--height) - 2 * var(--offset));
    /*width: 60px;*/
    height: calc(var(--height) - 2 * var(--offset));
    background: #fbfbfb;
    color: grey;
    border-radius: var(--width);
    transition: var(--duration);
    box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
    -moz-box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
    /*box-shadow: 0px 0px 1px 1px rgba(120, 124, 128, 0.78);*/
}
