* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');


:root {
    --maxWidth: 700px;
    --mainBlackBackground: #444;

    /*--mainItemsbackground: floralwhite;*/
    /*--mainItemsbackground: linear-gradient(45deg, #818085, #dddcdc);*/
    --mainItemsbackground: #f1f1f1;
    --hoverFilter: brightness(120%);
    --activeFilter: brightness(90%);
    --hoverTransition: filter 0.3s;
    --iconColor: floralwhite;
    --panelHeight: 60px;
    --transparentBackground: rgba(4, 4, 4, 0.5);
    --mediumTime: 0.5s;
    --white: #fbfbfb;
    --jotColor: #b2b2b2;
    --mainTranslate: 50%;
    --mainTranslateM: -50%;
    --mainYellow: #fec903;
    --mapHeight: 230px;
    --mainBorderRadius: 10px;
    --inputPadding: 7px 24px;
    --inputFontSize: 1rem;
    --inputMargin: 10px auto;
    --line-height: 24px;
}
::-webkit-scrollbar {
    width: 0;
}
html, body {
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    font-family: 'Roboto Condensed', sans-serif;
}
html{overflow-x: hidden;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.app {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100vw;
    min-height: 100vh;
    background-image: url("img/mainBg.jpg");
    background-attachment: fixed;
}
@media (max-width: 767px) {
    .app {
        flex-direction: column;
        justify-content: flex-start;
    }
}

/*.inp {*/
/*    position: relative;*/
/*    display: flex;*/
/*    margin: 10px auto;*/
/*    width: 70vw;*/
/*    height: 70px;*/
/*    overflow: hidden;*/
/*}*/

/*.inp_main {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    padding-top: 20px;*/
/*    outline: none;*/
/*    border: none;*/
/*    border-bottom: 1px solid;*/
/*    background: transparent;*/
/*    color: floralwhite;*/
/*}*/

/*.inp_label {*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    pointer-events: none;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    border-bottom: 1px solid;*/
/*    user-select: none;*/
/*}*/

/*.inp_label::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    left: 0;*/
/*    bottom: -2px;*/
/*    !*border-bottom: 3px solid #5fa8d3;*!*/
/*    border-bottom: 3px solid floralwhite;*/
/*    transform: translateX(-100%);*/
/*    transition: transform .3s ease;*/
/*}*/

/*.inp_span {*/
/*    position: absolute;*/
/*    bottom: 8px;*/
/*    left: 0;*/
/*    transition: all .3s ease;*/
/*}*/

/*.inp .inp_main:focus + .inp_label .inp_span,*/
/*.inp .inp_main:valid + .inp_label .inp_span {*/
/*    transform: translateY(-150%);*/
/*    font-size: 18px;*/
/*    !*color: #5fa8d3;*!*/
/*    color: floralwhite;*/
/*}*/

/*.inp .inp_main:focus + .inp_label::after,*/
/*.inp .inp_main:valid + .inp_label::after {*/
/*    transform: translateX(0%);*/
/*}*/

/*select {*/
/*    background: transparent;*/
/*}*/

.box{
    width: 100%;
    height: 40px;
    background: #fec903;
}
.box + .box{
    margin-top: 15px;
}
