@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  !*font-family: 'Rubik', sans-serif;*!*/

/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}


:root {
    --maxWidth: 700px;
    --mainBlackBackground: #444;

    /*--mainItemsbackground: floralwhite;*/
    /*--mainItemsbackground: linear-gradient(45deg, #818085, #dddcdc);*/
    --mainItemsbackground: #f1f1f1;
    --hoverFilter: brightness(120%);
    --activeFilter: brightness(90%);
    --hoverTransition: filter 0.3s;
    --iconColor: floralwhite;
    --panelHeight: 60px;
    --transparentBackground: rgba(4, 4, 4, 0.5);
    --mediumTime: 0.5s;
    --white: #fbfbfb;
    --jotColor: #b2b2b2;
    --mainTranslate: 50%;
    --mainTranslateM: -50%;
    --mainYellow: #fec903;
    --mapHeight: 230px;
    --mainBorderRadius: 10px;
    --inputPadding: 7px 24px;
    --inputFontSize: 1rem;
    --inputMargin: 10px auto;
    --line-height: 24px;
}
::-webkit-scrollbar {
    width: 0;
}
html, body {
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    font-family: 'Roboto Condensed', sans-serif;
}
html{overflow-x: hidden;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.app {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100vw;
    min-height: 100vh;
    background-image: url(/static/media/mainBg.9923b19d.jpg);
    background-attachment: fixed;
}
@media (max-width: 767px) {
    .app {
        flex-direction: column;
        justify-content: flex-start;
    }
}

/*.inp {*/
/*    position: relative;*/
/*    display: flex;*/
/*    margin: 10px auto;*/
/*    width: 70vw;*/
/*    height: 70px;*/
/*    overflow: hidden;*/
/*}*/

/*.inp_main {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    padding-top: 20px;*/
/*    outline: none;*/
/*    border: none;*/
/*    border-bottom: 1px solid;*/
/*    background: transparent;*/
/*    color: floralwhite;*/
/*}*/

/*.inp_label {*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    pointer-events: none;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    border-bottom: 1px solid;*/
/*    user-select: none;*/
/*}*/

/*.inp_label::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    left: 0;*/
/*    bottom: -2px;*/
/*    !*border-bottom: 3px solid #5fa8d3;*!*/
/*    border-bottom: 3px solid floralwhite;*/
/*    transform: translateX(-100%);*/
/*    transition: transform .3s ease;*/
/*}*/

/*.inp_span {*/
/*    position: absolute;*/
/*    bottom: 8px;*/
/*    left: 0;*/
/*    transition: all .3s ease;*/
/*}*/

/*.inp .inp_main:focus + .inp_label .inp_span,*/
/*.inp .inp_main:valid + .inp_label .inp_span {*/
/*    transform: translateY(-150%);*/
/*    font-size: 18px;*/
/*    !*color: #5fa8d3;*!*/
/*    color: floralwhite;*/
/*}*/

/*.inp .inp_main:focus + .inp_label::after,*/
/*.inp .inp_main:valid + .inp_label::after {*/
/*    transform: translateX(0%);*/
/*}*/

/*select {*/
/*    background: transparent;*/
/*}*/

.box{
    width: 100%;
    height: 40px;
    background: #fec903;
}
.box + .box{
    margin-top: 15px;
}

.Panel_panel__3Xy1u {
    /*position: fixed;*/
    /*left: 0;*/
    /*bottom: 50%;*/
    /*transform: translateY(50%);*/
}

.Panel_panelItems__1LeLT {
    height: 33vh;
    /*width: 200px;*/
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Panel_button__3LLtL {
    flex: 1 1;
    /*background: cadetblue;*/
    color: var(--mainBlackBackground);
    background: rgba(255,255,255, 0.3);
    cursor: pointer;
    padding: 7px 40px 5px;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-left: 2vw;
    border-radius: 8px;
    transition: background 0.2s, filter 0.2s;
}

.Panel_button__3LLtL + .Panel_button__3LLtL {
    margin-top: 15px;
}
.Panel_button__3LLtL:hover{
    background: #fbfbfb;
}

.Panel_button__3LLtL:active {
    transform: scale(0.97);
}

.Panel_icon__8UXWL {
    height: 25px;
    width: auto;
}

.Panel_span__3T4Wu {
    margin-left: 20px;
    font-size: 20px;
}


@media (max-width: 767px) {


    .Panel_panel__3Xy1u {
        position: fixed;
        bottom: 0;
        transform: translateY(0);
        display: flex;
        height: var(--panelHeight);
        width: 100%;
        /*max-width: var(--maxWidth);*/
        background: var(--mainBlackBackground);
        transition: all 0.5s;
        opacity: 1;
        z-index: 30;
    }

    .Panel_hide__2nTsg {
        opacity: 0;
        transform: translateY(100%);
    }

    .Panel_panelItems__1LeLT {
        display: flex;
        width: 100%;
        flex-direction: row;
        height: auto;
        /*text-align: center;*/
    }

    .Panel_button__3LLtL {
        flex: 1 1;
        /*background: cadetblue;*/
        color: var(--iconColor);
        cursor: pointer;
        transition: filter 0.2s;
        padding: 7px 5px 5px;
        display: flex;
        flex-direction: column;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        background: none;
    }

    .Panel_button__3LLtL:hover{
        background: none;
    }

    .Panel_button__3LLtL + .Panel_button__3LLtL {
        margin-left: 10px;
        margin-top: 0;
    }

    .Panel_button__3LLtL:active {
        transform: scale(0.97);
    }

    .Panel_icon__8UXWL {
        height: 55%;
        width: auto;
    }

    .Panel_span__3T4Wu {
        margin-top: 5px;
        font-size: 12px;
        margin-left: 0;
    }
}
.Main_main__3N3Np{
    width: 100%;
    max-width: var(--maxWidth);
    min-height: calc(100vh - var(--panelHeight));
    transition: all 0.6s;
     display: flex;
     overflow-y: auto;
    margin-bottom: var(--panelHeight);
}
.Main_full__9u36T{
    min-height: 100%;
    margin-bottom: 0;
}

.StartPage_authForm__3rWbq{
    display: flex;
    width: 100%;
    max-width: var(--maxWidth);
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.StartPage_authFormItems__29rJ0{
    display: flex;
    flex-direction: column;
    width: 80%;
}

/*.authFormItems input{*/
/*    font-size: 1.2rem;*/
/*    text-align: center;*/
/*    padding: 5px 10px;*/
/*    margin-bottom: 20px;*/
/*    outline: none;*/
/*    border-radius: 5px;*/
/*}*/

.StartPage_authFormItems__29rJ0 button{
    font-size: 1.1rem;
    padding: 5px;
    width: 100px;
    align-self: flex-end;
    border-radius: var(--mainBorderRadius);
    border: 3px solid #bebec0;
    color: white;
    background: rgba(4,4,4, 0.4);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.StartPage_inputGroup__1_gnI {
    width: 100%;
    position: relative;
    border: 3px solid #bebec0;
    border-radius: var(--mainBorderRadius);
    margin-bottom: 30px;
    background-color: rgba(4,4,4, 0.4);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
}

.StartPage_inputGroup__1_gnI input {
    padding: var(--inputPadding);
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    color: white;
    font-size: var(--inputFontSize);
    line-height: var(--line-height);
}

.StartPage_inputGroup__1_gnI:hover,
.StartPage_inputGroup__1_gnI:focus-within {
    border-color: rgba(4,4,4, 0.4);
    background: #fbfbfb;

}

.StartPage_inputGroup__1_gnI input:focus,
.StartPage_inputGroup__1_gnI:hover input {
    color: rgba(4,4,4, 0.8);
}

.StartPage_inputGroup__1_gnI label {
    position: absolute;
    background-color: transparent;
    padding: var(--inputPadding);
    line-height: var(--line-height);
    top: 0;
    left: 0;
    bottom: 0;
    /*color: var(--text-light);*/
    cursor: text;
    transition: top 0.2s;
    pointer-events: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: var(--inputFS);
    color: white;
}

.StartPage_inputGroup__1_gnI:hover label {
    color: var(--primary);
}

.StartPage_inputGroup__1_gnI input:not(:-ms-input-placeholder) ~ label {
    padding: 0 8px;
    /*background-color: var(--body);*/
    /*top: -12px;*/
    top: -28px;
    left: 16px;
    bottom: auto;
    color: white;
}

.StartPage_inputGroup__1_gnI:focus-within label,
.StartPage_inputGroup__1_gnI input:not(:placeholder-shown) ~ label {
    padding: 0 8px;
    /*background-color: var(--body);*/
    /*top: -12px;*/
    top: -28px;
    left: 16px;
    bottom: auto;
    color: white;
}
.New_newOrderMain__2TT4D {
    display: flex;
    align-items: center;
    width: 100%;
    /*height: calc(100vh - var(--panelHeight));*/
    padding: 1% 5%;
    /*overflow-y: auto;*/
    /*position: relative;*/
}

.New_newOrder__evqqr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

     /*min-height: calc(100vh - var(--panelHeight));*/
    padding: 5%;
    transition: all 0.5s ease-in;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    /*transform: translateY(25%);*/
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    color: #444444;
}


.New_hidden__1LZoN {
    /*opacity: 0.3;*/
    pointer-events: none;
    /*background: rgba(4,4,4,0.6);*/
    /*filter: brightness(0.3);*/
}

.New_ordersForm__1lLMG {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.New_ordersForm__1lLMG input,
.New_ordersForm__1lLMG select,
.New_ordersForm__1lLMG option {
    font-size: 1.2rem;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 20px;
    outline: none;
    border-radius: 5px;
    background: var(--white);
    border: 1px solid black;
    /*-webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);*/
    /*-moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);*/
    /*box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);*/
}

.New_ordersForm__1lLMG input:disabled,
.New_ordersForm__1lLMG select:disabled,
.New_ordersForm__1lLMG option:disabled {
    border-color: grey;
    background: var(--mainItemsbackground);
}


.New_button__1LZSE {
    font-size: 1.1rem;
    padding: 5px 30px;
    /*align-self: flex-end;*/
    border-radius: 5px;
    background: green;
    color: var(--white);
    border: 1px solid green;
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.New_button__1LZSE:disabled {
    color: grey;
    background: none;
    border-color: grey;
    box-shadow: none;
    background: var(--mainItemsbackground);
}

.New_itemsField__2kVmc {
    height: 0;
    /*background: rgba(4,4,4,0.1);*/
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.New_opn__1PM34 {
    /*height: 17vh;*/
    height: 132px;
    overflow: auto;
    margin-bottom: 20px;
    /*padding: 10px 0;*/
}

.New_box__1wQ4h {
    width: 95%;
    padding: 8px 0 8px 30px;
    /*background: var(--white);*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    border-radius: 2px;
    color: var(--white);
    background: var(--mainBlackBackground);
    text-align: left;
    cursor: pointer;
}

.New_box__1wQ4h + .New_box__1wQ4h {
    margin-top: 15px;
}

.New_refresh__3DLvP {
    font-size: 22px;
    position: absolute;
    right: 7%;
    transform: translate(15px, -49px);
}

.New_popuItem__3lUpQ {
    display: block;
    text-align: left;
}

.New_popupItm__3azT0 {
    width: 100%;
    min-height: 25px;
}

.New_pp__1CtKC {
    position: absolute;
    right: 20px;
    bottom: 20px;
    background: rgba(11, 252, 3, 0.5);
    padding: 10px 12px;
    border-radius: 50%;
    cursor: pointer;

}

.New_ok__2jaP1 {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 25px;
    color: rgba(11, 252, 3, 0.5);
    cursor: pointer;
    cursor: pointer;

}

.New_clz__31DHl {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    color: brown;
    cursor: pointer;
}

.New_buttonBlock__2ng5-{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


.New_checkBox__3mioc {
    /*--firstColor: #243743;*/
    --firstColor: #b8b7b8;
    /*--secondColor: #28b78d;*/
    --secondColor: rgb(40, 135, 212);
    --width: 35px;
    --height: 22px;
    --offset: 0px;
    --duration: 0.3s;
    cursor: pointer;
    width: var(--width);
}

.New_checkBox__3mioc:hover{
    filter: brightness(1.2);
}

.New_checkBoxInput__36TCR {
display: none;
}
.New_checkBoxInput__36TCR:checked ~ .New_checkBoxInputDiv__2PipM {
    background: var(--secondColor);
}

.New_checkBoxInput__36TCR:checked ~ .New_checkBoxInputDiv__2PipM::before {
    left: calc(var(--width) - var(--height) + var(--offset));
    /*background: var(--secondColor);*/
}


.New_checkBoxInputDiv__2PipM {
    /*position: absolute;*/
    width: var(--width);
    height: var(--height);
    border-radius: var(--width);
    background: var(--firstColor);
    position: relative;
    transition: var(--duration);
    box-shadow: inset 0 0 2px rgba(0,0,0, 0.1);
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0, 0.1);
    -moz-box-shadow: inset 0 0 2px rgba(0,0,0, 0.1);
    /*box-shadow: 0px 0px 1px 1px rgba(120, 124, 128, 0.78) inset;*/
}
.New_checkBoxInputDiv__2PipM::before{
    content: '';
    position: absolute;
    top: var(--offset);
    left: var(--offset);
    width: calc(var(--height) - 2 * var(--offset));
    /*width: 60px;*/
    height: calc(var(--height) - 2 * var(--offset));
    background: #fbfbfb;
    color: grey;
    border-radius: var(--width);
    transition: var(--duration);
    box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
    -moz-box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
    /*box-shadow: 0px 0px 1px 1px rgba(120, 124, 128, 0.78);*/
}

.Popup_popup__1Cbt3 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--transparentBackground);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}
.Popup_opnP__1eWGY{
    opacity: 1;
}

.Popup_popupItem__2Znku{
    width: 90%;
    max-width: var(--maxWidth);
    background: var(--white);
    cursor: auto;
    cursor: initial;
    padding: 20px;
    color: var(--mainBlackBackground);
    position: fixed;
    left: 50%;
    transform: translate(-50%, 100%);
    opacity: 0;
    bottom: calc(var(--panelHeight) + 5vw);
    transition: all 0.3s ease-in;
    border-radius: 5px;
}
.Popup_opn__3zdXS{
    transform: translate(-50%, 0);
    opacity: 1;
}

.Orders_ordersField__1PiP3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - var(--panelHeight));
    padding: 5%;
    transition: all 0.5s ease-in;
    overflow-y: auto;
}

.Orders_hidden__6AANp {
    pointer-events: none;
    overflow-y: hidden;
}

.Orders_box__1li_o {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px 15px;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    /* justify-content: space-between; */
    align-items: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.Orders_box__1li_o + .Orders_box__1li_o {
    margin-top: 15px;
}


.Orders_description__3RyZd {
    display: flex;
    width: 100%;
    text-align: left;
}

.Orders_dutyMarker__2DiLO {

    color: #2468ad;
    margin: 0 auto;
}

.Orders_description__3RyZd + .Orders_description__3RyZd {
    margin-top: 7px;
}

.Orders_popupItm__24BtT {
    width: 100%;
    flex-direction: column;
    text-align: left;
    display: flex;
}

.Orders_clz__1BLvS {
    position: absolute;
    top: 13px;
    right: 13px;
    font-size: 17px;
    color: brown;
    cursor: pointer;
}

.Orders_ok__3n7sd {
    font-size: 21px;
    color: green;
    cursor: pointer;
    margin-right: 5px;
}

.Orders_okBtn__3CuYA {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid green;
    right: 23px;
    bottom: 120px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    width: 100%;
}


.Orders_okBtn__3CuYA:active,
.Orders_destBtn__3GwK1:active {
    transform: scale(0.97);
}


.Orders_cardName__3BvHM {
    font-size: 1.1rem;
}

.Orders_cardNameD__1BaIC {
    margin: 0 0 10px;
    font-size: 1.1rem;
    color: #2468ad;
    padding: 5px 10px;
    border: 1px solid #2468ad;
    border-radius: 5px;
    text-align: center;
}

.Orders_cardNameD__1BaIC:active {
    transform: scale(0.97);
}

.Orders_cardAddr__fjizi {
    font-size: 1rem;
    margin-top: 9px;
}

.Orders_cardCall__2qB4R {
    margin-left: 15px;
}

.Orders_cardCallIco__1r1Ms {
    color: #2468ad;
    font-size: 41px;
    margin-bottom: -4px;
}

.Orders_cardCallIco__1r1Ms:active {
    transform: scale(0.97);
}

.Orders_cardDate__SzkTE {
    position: absolute;
    top: 10px;
    left: 15px;
}

@media (max-width: 360px) {
    .Orders_cardDate__SzkTE {
        font-size: 0.9rem;
    }
}

.Orders_cardItems__2haeD {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

.Orders_cardItemsd__2Tg5e {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    max-width: 57%;
}

.Orders_cardItemsd__2Tg5e + .Orders_cardItemsd__2Tg5e {
    margin-left: auto;
}

.Orders_cardClose__3JIzO {
    display: flex;
    width: 100%;
    grid-gap: 2%;
    gap: 2%;
    height: 0;
    align-items: center;
    overflow: hidden;
    transition: 0.2s;
}


.Orders_cardProlongation__3GsJ9 {
    display: flex;
    width: 100%;
    grid-gap: 2%;
    gap: 2%;
    height: 0;
    /*background: #b2b2b2;*/
    overflow-y: hidden;
    transition: 0.2s;
}

.Orders_prolog__2gJU4 {
    height: 45px;
}

.Orders_cardProlongation__3GsJ9 input,
.Orders_cardProlongation__3GsJ9 select {
    font-size: 1rem;
    text-align: center;
    padding: 5px 7px;
    outline: none;
    border-radius: 5px;
    background: white;
    border: 1px solid grey;
    width: 100%;
    color: #444444;
}

@media (max-width: 360px) {
    .Orders_cardProlongation__3GsJ9 input,
    .Orders_cardProlongation__3GsJ9 select {
        font-size: 0.8rem;
    }
}


.Orders_cardProlongation__3GsJ9 button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 13px;
    border: 1px solid green;
    /* top: 5px; */
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    background: none;
}

.Orders_okBTN__34wb_ {
    font-size: 15px;
    color: green;
    cursor: pointer;
}

.Orders_checkBox__FxVp7 {
    margin-left: 10px;
}

.Orders_checkBoxInputDiv__2NZLz {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    /*border: 1px solid #2468ad;*/


    border: 1px solid rgb(231, 239, 248);
    background: rgb(241, 248, 254);
    /*box-shadow: 0 2px 15px -5px #2196f3;*/
    /* top: 5px; */
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    background: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.Orders_timerBtn__3xstf {
    color: #2468ad;
    font-size: 21px;
    transition: 0.1s;
}


.Orders_checkBoxMB__OJPLT,
.Orders_checkBoxInput__3nDBX,
.Orders_checkBoxInpDestr__2TAIw {
    display: none;
}

.Orders_checkBoxInput__3nDBX:checked ~ .Orders_checkBoxInputDiv__2NZLz {
    background: #2468ad;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}


.Orders_checkBoxInput__3nDBX:checked ~ .Orders_checkBoxInputDiv__2NZLz .Orders_timerBtn__3xstf {
    color: #fbfbfb;
}

.Orders_ds__7vjAm {
    font-size: 21px;
    /*color: #a70000;*/
    color: #d32f2f;
    cursor: pointer;
    margin-right: 5px;
}

.Orders_destBtn__3GwK1 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #d32f2f;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    transition: 0.2s;
}

.Orders_checkBoxInpDestr__2TAIw:checked ~ .Orders_destBtn__3GwK1 {
    color: #fbfbfb;
    /*background: rgba(167, 0, 0, 0.68);*/
    background: #d32f2f;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}

.Orders_checkBoxInpDestr__2TAIw:checked ~ .Orders_destBtn__3GwK1 .Orders_ds__7vjAm {
    color: #fbfbfb;
}

.Orders_recBTN__Mg_R5 {
    font-size: 21px;
    color: #ffa000;
    cursor: pointer;
}

.Orders_recBtn__2yax1 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgb(231, 239, 248);
    background: rgb(241, 248, 254);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    transition: 0.2s;
    background: none;
}

.Orders_checkBoxMB__OJPLT:checked ~ .Orders_recBtn__2yax1 {
    background: #ffa000;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}

.Orders_checkBoxMB__OJPLT:checked ~ .Orders_recBtn__2yax1 .Orders_recBTN__Mg_R5 {
    color: #fbfbfb;
}

.Orders_moneyBack__i-Lzy {
    display: flex;
    width: 100%;
    height: 0;
    grid-gap: 3%;
    gap: 3%;
    overflow-y: hidden;
}

.Orders_moneyBack__i-Lzy input {
    width: calc(97% / 2);
    font-size: 0.8rem;
    text-align: center;
    outline: none;
    border: 1px solid grey;
    border-radius: 5px;
    transition: 0.6s;
}

.Orders_mbopn__10igN {
    height: 35px;
    margin-bottom: 10px;
}

.Orders_recBtn__2yax1:active,
.Orders_checkBoxInputDiv__2NZLz:active,
.Orders_checkBoxInputDiv__2NZLz:active {
    transform: scale(0.97);
}
.Clients_clientsField__2G-aZ {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - var(--panelHeight));
    padding: 5%;
    transition: all 0.5s ease-in;
    overflow-y: auto;
}

.Clients_hidden__3jjvj {
    /*opacity: 0;*/
    pointer-events: none;
    /*filter: brightness(0.2);*/
    overflow-y: hidden;
}

.Clients_box__3-CGp {
    display: flex;
    width: 100%;
    padding: 13px 15px 10px;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.Clients_box__3-CGp + .Clients_box__3-CGp {
    margin-top: 15px;
}

.Clients_description__3sZXI {
    display: flex;
    /*flex-direction: column;*/
    justify-content: space-between;
    flex: 1 1;
    text-align: left;
    /*min-height: 60px;*/
}

.Clients_description__3sZXI span {
    margin-bottom: 5px;
}

.Clients_description__3sZXI > div > span + span {
    margin-left: 20px;
}

.Clients_description__3sZXI + .Clients_description__3sZXI {
    flex: 1 1;
    text-align: end;
}

.Clients_popupItm__1fn-E {
    width: 100%;
    height: calc(100vh - 10vw - 40px - var(--panelHeight));
    display: flex;
    flex-direction: column;
}

.Clients_clz__1bhHU {
    position: absolute;
    top: 13px;
    right: 13px;
    font-size: 17px;
    color: brown;
    cursor: pointer;
}

.Clients_popupName__10meE {
    /*position: absolute;*/
    /*top: 7px;*/
    /*left: 20px;*/
    font-size: 1.1em;
    font-weight: bold;
    text-align: left;
}

.Clients_popupName__10meE + .Clients_popupName__10meE {
    margin-top: 8px;
    font-size: 0.9em;
}

h4 {
    margin-top: 10px;
}

/*.ordersField{*/
/*    position: relative;*/
/*    top: 10px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    !*width: 95%;*!*/
/*    height: calc(100% - 35px);*/
/*    !*background: #fec903;*!*/
/*    overflow-y: auto;*/
/*}*/

.Clients_ordersField__3IdYO {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    margin-top: 10px;
    overflow-y: auto;
}

.Clients_orderItm__3ug-U {
    display: flex;
    width: 100%;
    padding: 15px 0 5px;
    text-align: left;
    border-bottom: 1px solid grey;
    font-size: 0.8em;
    align-items: center;
}

.Clients_mstspn__1Ht6v {
    margin: 0 10px;
    text-align: center;
}

.Clients_lstspn__3K7ni {
    margin-left: auto;
    text-align: center;
}

@media (max-width: 360px) {
    h4 {
        font-size: 0.8em;
    }

    .Clients_orderItm__3ug-U {
        font-size: 0.6em;
        padding: 10px 0 5px;

    }
}
.Stats_statField__3SlJv {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - var(--panelHeight));
    padding: 5%;
}

.Stats_box__2SsAu {
    display: flex;
    width: 100%;
    padding: 10px 15px;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    align-items: center;
    /*justify-content: space-evenly;*/
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.Stats_box__2SsAu + .Stats_box__2SsAu {
    margin-top: 15px;
}

.Stats_popupItm__L9Ls4 {
    width: 100%;
    height: calc(100vh - 10vw - 60px - var(--panelHeight));
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.Stats_clz__3PdM1 {
    position: absolute;
    top: 13px;
    right: 13px;
    font-size: 17px;
    color: brown;
    cursor: pointer;
    z-index: 10;
}

.Stats_statsDate__2VxL4 {
    width: 100%;
    margin-bottom: 30px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 20px);
}

.Stats_statInfoField__2TlaY {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    margin-top: 30px;
    overflow-y: auto;
}

.Stats_statFieldItm__35oXl{
    display: flex;
    width: 100%;
    padding: 15px 0 5px;
    text-align: left;
    border-bottom: 1px solid grey;
    font-size: 0.8em;
    align-items: center;
}
.Stats_mstspn__13pce{
    margin: 0 10px;
}
.Stats_lstspn__31Bic{
    display: flex;
    align-items: center;
    margin-left: auto;
    min-width: 55px;
    text-align: right
}
.Stats_itog__3sIbK{
    width: 60%;
    height: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 1.2rem;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.Storage_storage__kG417 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - var(--panelHeight));
    padding: 1% 5%;
    transition: all 0.5s ease-in;
    overflow-y: auto;
}

.Storage_addItem__3GXdT {
    position: fixed;
    bottom: 90px;
    right: 30px;
    padding: 5px 7px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    cursor: pointer;
}

.Storage_addItem__3GXdT:active {
    transform: scale(0.97);
}

.Storage_addItemIco__1uxEk {
    font-size: 45px;
    color: #fbfbfb;
}

.Storage_box__u8HSp {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 15px;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.Storage_box__u8HSp + .Storage_box__u8HSp {
    margin-top: 15px;
}

.Storage_searchPanel__aX58b {
    display: flex;
    width: 100%;
    height: 70px;
    background: rgba(4, 4, 4, 0.6);
    position: fixed;
    top: 0;
}

.Storage_items__sWZef {
    /*position: fixed;*/
    top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - var(--panelHeight));
    overflow-y: auto;
}

.Storage_boxTitle__2Ooea {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.Storage_boxInfo__Pc2WW {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #2468ad;
    align-items: center;
}

.Storage_boxInfoPrice__2WRH0 {
    margin-left: auto;
    text-align: right;
    padding-left: 10px;
}

.Storage_popupItm__11RmY {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Storage_clz__Lwtf1 {
    position: absolute;
    top: 13px;
    right: 13px;
    font-size: 17px;
    color: brown;
    cursor: pointer;
}

.Storage_repairField__XY3Hs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 5% 0;
}

.Storage_repair__2V-6U {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.Storage_repair__2V-6U input {
    width: 100%;
    padding: 6px 10px;
    border: 1px solid #2468ad;
    border-radius: 5px;
    text-overflow: ellipsis;
}

.Storage_repairItem__1-HZS {
    width: 60%;
    background: #2468ad;
    padding: 6px 0;
    color: #fbfbfb;
    border-radius: 5px;
    border: 1px solid #2468ad;
    margin-left: 15px;
}

.Storage_removeItem__3Rf6l {
    margin-top: 10px;
    width: 100%;
    background: red;
    padding: 6px 0;
    color: #fbfbfb;
    border-radius: 5px;
    border: 1px solid red;
}

.Storage_repairItem__1-HZS:active,
.Storage_removeItem__3Rf6l:active {
    transform: scale(0.97);
}

.Storage_newInp__1xlUe {
    display: flex;
    width: 100%;
    grid-gap: 5px;
    gap: 5px;
}

.Storage_newInp__1xlUe input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid grey;
    border-radius: 4px;
    text-overflow: ellipsis;
}

.Storage_newInp__1xlUe input:last-child {
    width: 42%;
}

.Storage_newInpBtn__usa_N {
    padding: 6px 0;
    background: green;
    border: 1px solid green;
    border-radius: 5px;
    color: #fbfbfb;
}
