.main{
    width: 100%;
    max-width: var(--maxWidth);
    min-height: calc(100vh - var(--panelHeight));
    transition: all 0.6s;
     display: flex;
     overflow-y: auto;
    margin-bottom: var(--panelHeight);
}
.full{
    min-height: 100%;
    margin-bottom: 0;
}
