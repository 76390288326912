.authForm{
    display: flex;
    width: 100%;
    max-width: var(--maxWidth);
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.authFormItems{
    display: flex;
    flex-direction: column;
    width: 80%;
}

/*.authFormItems input{*/
/*    font-size: 1.2rem;*/
/*    text-align: center;*/
/*    padding: 5px 10px;*/
/*    margin-bottom: 20px;*/
/*    outline: none;*/
/*    border-radius: 5px;*/
/*}*/

.authFormItems button{
    font-size: 1.1rem;
    padding: 5px;
    width: 100px;
    align-self: flex-end;
    border-radius: var(--mainBorderRadius);
    border: 3px solid #bebec0;
    color: white;
    background: rgba(4,4,4, 0.4);
    -webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.inputGroup {
    width: 100%;
    position: relative;
    border: 3px solid #bebec0;
    border-radius: var(--mainBorderRadius);
    margin-bottom: 30px;
    background-color: rgba(4,4,4, 0.4);
    -webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
}

.inputGroup input {
    padding: var(--inputPadding);
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    color: white;
    font-size: var(--inputFontSize);
    line-height: var(--line-height);
}

.inputGroup:hover,
.inputGroup:focus-within {
    border-color: rgba(4,4,4, 0.4);
    background: #fbfbfb;

}

.inputGroup input:focus,
.inputGroup:hover input {
    color: rgba(4,4,4, 0.8);
}

.inputGroup label {
    position: absolute;
    background-color: transparent;
    padding: var(--inputPadding);
    line-height: var(--line-height);
    top: 0;
    left: 0;
    bottom: 0;
    /*color: var(--text-light);*/
    cursor: text;
    transition: top 0.2s;
    pointer-events: none;
    user-select: none;
    font-size: var(--inputFS);
    color: white;
}

.inputGroup:hover label {
    color: var(--primary);
}

.inputGroup:focus-within label,
.inputGroup input:not(:placeholder-shown) ~ label {
    padding: 0 8px;
    /*background-color: var(--body);*/
    /*top: -12px;*/
    top: -28px;
    left: 16px;
    bottom: auto;
    color: white;
}