.panel {
    /*position: fixed;*/
    /*left: 0;*/
    /*bottom: 50%;*/
    /*transform: translateY(50%);*/
}

.panelItems {
    height: 33vh;
    /*width: 200px;*/
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.button {
    flex: 1;
    /*background: cadetblue;*/
    color: var(--mainBlackBackground);
    background: rgba(255,255,255, 0.3);
    cursor: pointer;
    padding: 7px 40px 5px;
    display: flex;
    align-items: center;
    user-select: none;
    margin-left: 2vw;
    border-radius: 8px;
    transition: background 0.2s, filter 0.2s;
}

.button + .button {
    margin-top: 15px;
}
.button:hover{
    background: #fbfbfb;
}

.button:active {
    transform: scale(0.97);
}

.icon {
    height: 25px;
    width: auto;
}

.span {
    margin-left: 20px;
    font-size: 20px;
}


@media (max-width: 767px) {


    .panel {
        position: fixed;
        bottom: 0;
        transform: translateY(0);
        display: flex;
        height: var(--panelHeight);
        width: 100%;
        /*max-width: var(--maxWidth);*/
        background: var(--mainBlackBackground);
        transition: all 0.5s;
        opacity: 1;
        z-index: 30;
    }

    .hide {
        opacity: 0;
        transform: translateY(100%);
    }

    .panelItems {
        display: flex;
        width: 100%;
        flex-direction: row;
        height: auto;
        /*text-align: center;*/
    }

    .button {
        flex: 1;
        /*background: cadetblue;*/
        color: var(--iconColor);
        cursor: pointer;
        transition: filter 0.2s;
        padding: 7px 5px 5px;
        display: flex;
        flex-direction: column;
        user-select: none;
        background: none;
    }

    .button:hover{
        background: none;
    }

    .button + .button {
        margin-left: 10px;
        margin-top: 0;
    }

    .button:active {
        transform: scale(0.97);
    }

    .icon {
        height: 55%;
        width: auto;
    }

    .span {
        margin-top: 5px;
        font-size: 12px;
        margin-left: 0;
    }
}