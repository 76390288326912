.storage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - var(--panelHeight));
    padding: 1% 5%;
    transition: all 0.5s ease-in;
    overflow-y: auto;
}

.addItem {
    position: fixed;
    bottom: 90px;
    right: 30px;
    padding: 5px 7px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    cursor: pointer;
}

.addItem:active {
    transform: scale(0.97);
}

.addItemIco {
    font-size: 45px;
    color: #fbfbfb;
}

.box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 15px;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    -webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.box + .box {
    margin-top: 15px;
}

.searchPanel {
    display: flex;
    width: 100%;
    height: 70px;
    background: rgba(4, 4, 4, 0.6);
    position: fixed;
    top: 0;
}

.items {
    /*position: fixed;*/
    top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - var(--panelHeight));
    overflow-y: auto;
}

.boxTitle {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.boxInfo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #2468ad;
    align-items: center;
}

.boxInfoPrice {
    margin-left: auto;
    text-align: right;
    padding-left: 10px;
}

.popupItm {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.clz {
    position: absolute;
    top: 13px;
    right: 13px;
    font-size: 17px;
    color: brown;
    cursor: pointer;
}

.repairField {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 5% 0;
}

.repair {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.repair input {
    width: 100%;
    padding: 6px 10px;
    border: 1px solid #2468ad;
    border-radius: 5px;
    text-overflow: ellipsis;
}

.repairItem {
    width: 60%;
    background: #2468ad;
    padding: 6px 0;
    color: #fbfbfb;
    border-radius: 5px;
    border: 1px solid #2468ad;
    margin-left: 15px;
}

.removeItem {
    margin-top: 10px;
    width: 100%;
    background: red;
    padding: 6px 0;
    color: #fbfbfb;
    border-radius: 5px;
    border: 1px solid red;
}

.repairItem:active,
.removeItem:active {
    transform: scale(0.97);
}

.newInp {
    display: flex;
    width: 100%;
    gap: 5px;
}

.newInp input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid grey;
    border-radius: 4px;
    text-overflow: ellipsis;
}

.newInp input:last-child {
    width: 42%;
}

.newInpBtn {
    padding: 6px 0;
    background: green;
    border: 1px solid green;
    border-radius: 5px;
    color: #fbfbfb;
}