.clientsField {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - var(--panelHeight));
    padding: 5%;
    transition: all 0.5s ease-in;
    overflow-y: auto;
}

.hidden {
    /*opacity: 0;*/
    pointer-events: none;
    /*filter: brightness(0.2);*/
    overflow-y: hidden;
}

.box {
    display: flex;
    width: 100%;
    padding: 13px 15px 10px;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    -webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.box + .box {
    margin-top: 15px;
}

.description {
    display: flex;
    /*flex-direction: column;*/
    justify-content: space-between;
    flex: 1;
    text-align: left;
    /*min-height: 60px;*/
}

.description span {
    margin-bottom: 5px;
}

.description > div > span + span {
    margin-left: 20px;
}

.description + .description {
    flex: 1;
    text-align: end;
}

.popupItm {
    width: 100%;
    height: calc(100vh - 10vw - 40px - var(--panelHeight));
    display: flex;
    flex-direction: column;
}

.clz {
    position: absolute;
    top: 13px;
    right: 13px;
    font-size: 17px;
    color: brown;
    cursor: pointer;
}

.popupName {
    /*position: absolute;*/
    /*top: 7px;*/
    /*left: 20px;*/
    font-size: 1.1em;
    font-weight: bold;
    text-align: left;
}

.popupName + .popupName {
    margin-top: 8px;
    font-size: 0.9em;
}

h4 {
    margin-top: 10px;
}

/*.ordersField{*/
/*    position: relative;*/
/*    top: 10px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    !*width: 95%;*!*/
/*    height: calc(100% - 35px);*/
/*    !*background: #fec903;*!*/
/*    overflow-y: auto;*/
/*}*/

.ordersField {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: 10px;
    overflow-y: auto;
}

.orderItm {
    display: flex;
    width: 100%;
    padding: 15px 0 5px;
    text-align: left;
    border-bottom: 1px solid grey;
    font-size: 0.8em;
    align-items: center;
}

.mstspn {
    margin: 0 10px;
    text-align: center;
}

.lstspn {
    margin-left: auto;
    text-align: center;
}

@media (max-width: 360px) {
    h4 {
        font-size: 0.8em;
    }

    .orderItm {
        font-size: 0.6em;
        padding: 10px 0 5px;

    }
}