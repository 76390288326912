.statField {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - var(--panelHeight));
    padding: 5%;
}

.box {
    display: flex;
    width: 100%;
    padding: 10px 15px;
    background: var(--mainItemsbackground);
    border-radius: 5px;
    align-items: center;
    /*justify-content: space-evenly;*/
    -webkit-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
    cursor: pointer;
}

.box + .box {
    margin-top: 15px;
}

.popupItm {
    width: 100%;
    height: calc(100vh - 10vw - 60px - var(--panelHeight));
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.clz {
    position: absolute;
    top: 13px;
    right: 13px;
    font-size: 17px;
    color: brown;
    cursor: pointer;
    z-index: 10;
}

.statsDate {
    width: 100%;
    margin-bottom: 30px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 20px);
}

.statInfoField {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: 30px;
    overflow-y: auto;
}

.statFieldItm{
    display: flex;
    width: 100%;
    padding: 15px 0 5px;
    text-align: left;
    border-bottom: 1px solid grey;
    font-size: 0.8em;
    align-items: center;
}
.mstspn{
    margin: 0 10px;
}
.lstspn{
    display: flex;
    align-items: center;
    margin-left: auto;
    min-width: 55px;
    text-align: right
}
.itog{
    width: 60%;
    height: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 1.2rem;
    position: absolute;
    bottom: 10px;
    right: 10px;
}