.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--transparentBackground);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}
.opnP{
    opacity: 1;
}

.popupItem{
    width: 90%;
    max-width: var(--maxWidth);
    background: var(--white);
    cursor: initial;
    padding: 20px;
    color: var(--mainBlackBackground);
    position: fixed;
    left: 50%;
    transform: translate(-50%, 100%);
    opacity: 0;
    bottom: calc(var(--panelHeight) + 5vw);
    transition: all 0.3s ease-in;
    border-radius: 5px;
}
.opn{
    transform: translate(-50%, 0);
    opacity: 1;
}
